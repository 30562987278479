import { FC, useState } from 'react';
import styled from 'styled-components';
import { DetectionImage } from '../shared/components/Popup';
import { Spinner } from '../shared/styles/Spinner';
import { apiBaseUrl } from '../utils/env';
import { BorderFreeRounded } from './Report/styles/Wrappers';

const RightSideBar = styled.div`
    position: absolute;
    top: 60px;
    right: 30px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.05);

    font-size: 20px;
    max-width: calc(100% - 50px);
    label {
        margin-bottom: 12px;
    }
`;

const ButtonHolders = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    height: 60px;
`;

const ToggleButton = styled.div<{ $isClicked?: boolean | null }>`
    height: 20px;
    background-color: ${(props) => (props.$isClicked ? '#a95' : 'rgba(255, 255, 255, 0.06)')};
    padding: 10px;
    border-radius: 10px;
`;

const ImageHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 1200px;
    overflow-y: scroll;
`;

const ConfidenceBarHolder = styled.div`
    display: flex;
    height: 100%;
    text-align: center;
    color: black;
    align-items: flex-end;
    margin-right: 10px;
`;

const ConfidenceCircle = styled.div<{ $confidence?: number | null }>`
    background-color: #ecda80;
    height: ${(props) => (props.$confidence ? props.$confidence + '%' : 'undefined')};
    border-radius: 4px;
    text-align: center;
    color: black;
`;

const ImageObjectWrapper = styled.span<{ $isClicked?: boolean | null }>`
    display: flex;
    align-items: center;
    align-self: flex-start;
    border-radius: 5px;
    background-color: ${(props) => (props.$isClicked ? '#a95' : 'undefined')};
    border: 2px solid transparent;
    &:hover {
        border: 2px solid #ecda80;
    }
`;
const ImageWrapper = styled.div`
    display: flex;
    width: 300px;
    overflow: hidden;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 20px;
`;

const SpaceOccuiperContainer = styled.div`
    display: flex;
`;

const Information = styled.div`
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    height: 23px;
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    font-size: 20px;
    white-space: nowrap;
`;

const SubTitle = styled.div`
    font-weight: 700;
    margin-right: 10px;
    margin-left: 10px;
`;

const LoadingElement = styled.div`
    height: 200px;
    width: 400px;
    margin-top: 100px;
    text-align: center;
    font-size: 23px;
`;

export type Candidate = {
    timestamp: string;
    matching_label: string;
    lat: number;
    lon: number;
    image_source: string;
    est_length: number;
    mmsi: number;
    uuid: string;
    speed?: number;
};

export const CandidateList: FC<{
    CandidatePositions: Candidate[] | undefined;
    clickedBoatIndex: number | null;
    confidenceList: number[];
    isLoading: boolean;
    candidatesError: string | null;
    setHoveredListBoatElement: (value: number | null) => void;
}> = ({
    CandidatePositions,
    clickedBoatIndex,
    confidenceList,
    isLoading,
    candidatesError,
    setHoveredListBoatElement,
}) => {
    const [showMatched, toggleShowMatched] = useState<boolean>(false);
    const [showConfidence, toggleShowConfidence] = useState<boolean>(true);
    return isLoading || CandidatePositions || candidatesError ? (
        <RightSideBar>
            {CandidatePositions ? (
                <BorderFreeRounded>
                    <ButtonHolders>
                        <ToggleButton $isClicked={showMatched} onClick={() => toggleShowMatched(!showMatched)}>
                            Hide matched
                        </ToggleButton>
                        <ToggleButton $isClicked={showConfidence} onClick={() => toggleShowConfidence(!showConfidence)}>
                            Show confidence
                        </ToggleButton>
                    </ButtonHolders>
                    <ImageHolder>
                        {CandidatePositions.map((item, index) => {
                            let conf = 0;
                            if (index < confidenceList.length) {
                                conf = confidenceList[index];
                            }
                            if (showMatched && item.matching_label === 'matched') {
                                return;
                            }
                            return (
                                <ImageObjectWrapper
                                    key={index}
                                    onMouseEnter={() => setHoveredListBoatElement(index)}
                                    onMouseLeave={() => setHoveredListBoatElement(null)}
                                    $isClicked={clickedBoatIndex === index}
                                >
                                    <ImageWrapper>
                                        <DetectionImage
                                            key={index}
                                            image_path={
                                                apiBaseUrl() + '/insights/image/sentinel2/' + item.uuid + '.png'
                                            }
                                        />
                                    </ImageWrapper>
                                    <TextWrapper>
                                        {typeof item.image_source === 'string' ? (
                                            <SpaceOccuiperContainer>
                                                <Information>
                                                    <img src="/images/icons/satellite.svg" alt="no source" />
                                                    <SubTitle>Satellite:</SubTitle>
                                                    {item.image_source}
                                                </Information>
                                                <div />
                                            </SpaceOccuiperContainer>
                                        ) : null}
                                        {typeof item.matching_label === 'string' ? (
                                            <>
                                                <SpaceOccuiperContainer>
                                                    <Information>
                                                        <SubTitle>Status:</SubTitle>
                                                        {item.matching_label}
                                                    </Information>
                                                    <div />
                                                </SpaceOccuiperContainer>
                                                {item.mmsi !== 0 ? (
                                                    <SpaceOccuiperContainer>
                                                        <Information>
                                                            <SubTitle>MMSI:</SubTitle>
                                                            {item.mmsi}
                                                        </Information>
                                                        <div />
                                                    </SpaceOccuiperContainer>
                                                ) : null}
                                            </>
                                        ) : null}

                                        {typeof item.speed === 'number' ? (
                                            <SpaceOccuiperContainer>
                                                <Information>
                                                    <SubTitle>AIS Speed:</SubTitle>
                                                    {item.speed} kn
                                                </Information>
                                                <div />
                                            </SpaceOccuiperContainer>
                                        ) : null}
                                        {typeof item.timestamp === 'string' ? (
                                            <SpaceOccuiperContainer>
                                                <Information>
                                                    <img src="/images/icons/calendar.svg" alt="no timestamp" />
                                                    <SubTitle>Time: </SubTitle>
                                                    {item.timestamp.slice(0, 10)},{' '}
                                                    {item.timestamp?.slice(11, 16) + ' GMT'}
                                                </Information>
                                                <div />
                                            </SpaceOccuiperContainer>
                                        ) : null}
                                        {typeof item.est_length === 'number' ? (
                                            <SpaceOccuiperContainer>
                                                <Information>
                                                    <SubTitle>Estimated length:</SubTitle>
                                                    {Math.floor(item.est_length * 10) / 10} m
                                                </Information>
                                                <div />
                                            </SpaceOccuiperContainer>
                                        ) : null}
                                    </TextWrapper>
                                    {showConfidence && conf !== 0 ? (
                                        <ConfidenceBarHolder>
                                            <ConfidenceCircle $confidence={conf}>
                                                Match
                                                <div style={{ margin: '0 4px' }}>Conf: </div>
                                                <div>{conf}%</div>
                                            </ConfidenceCircle>
                                        </ConfidenceBarHolder>
                                    ) : null}
                                </ImageObjectWrapper>
                            );
                        })}
                    </ImageHolder>
                </BorderFreeRounded>
            ) : (
                <BorderFreeRounded style={{ marginBottom: '0' }}>
                    <LoadingElement>
                        {candidatesError ? (
                            <div>{candidatesError}</div>
                        ) : (
                            <div>
                                <Spinner />
                                Searching candidate database
                            </div>
                        )}
                    </LoadingElement>
                </BorderFreeRounded>
            )}
        </RightSideBar>
    ) : null;
};
