/* eslint-disable complexity */
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { easeCubicInOut } from 'd3-ease';
import { FlyToInterpolator } from 'deck.gl/typed';
import { AppContext } from './Storyteller';
import generateGeoJsonLayer from './deckgl-layers/generateGeoJsonLayer';
import Scroll from './/Scroll';
import { Chapter, LayerConfig, StoryConfig } from './types/data';
// eslint-disable-next-line import/extensions
import BasicStoryTemplate from './BasicStoryTemplate';
import IntroductionChapter from './IntroductionChapter';
import { MapboxLayerType } from './types/context';

const ChapterContent = styled.div<{ active: boolean; justifyContent: string; alignItems: string }>`
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: ${(props) => props.justifyContent};
    align-items: ${(props) => props.alignItems};
    padding: 2rem;
    transition: all 0.3s ease-in;
    opacity: ${(props) => (props.active ? 1 : 0)};
`;

const EmptyContent = styled.div`
    height: 500px;
    opacity: 0;
`;

const Story: FC<{ data: StoryConfig }> = ({ data }) => {
    const {
        state: { isJourneyMode, deckglLayers, activeStep, currentScrollTop, mapboxLayers },
        actions: { setViewport, setDeckglLayers, setActiveStep, setCurrentScrollTop, setPopups, setMapboxLayers },
    } = useContext(AppContext);

    const handleDeckglLayers = (layer: any, stateLayers: any) => {
        const layerExists = stateLayers.some(
            (stateLayer: any) => layer.id === stateLayer.id || stateLayer.id.includes(layer.id)
        );

        // If layer exists, we filter it out and add it again (as visibility, colors etc can be changed)
        if (layerExists) {
            stateLayers = stateLayers.filter((stateLayer: any) => stateLayer.id !== layer.id);
        }

        const newLayer = generateGeoJsonLayer(
            layer.id,
            layer.data,
            layer.visible,
            layer.opacity,
            layer.fill,
            layer.lineColor,
            layer.fillColor,
            layer.pointRadius,
            layer.lineWidth
            // layer.transitions
        );
        stateLayers = [...stateLayers, newLayer];

        return stateLayers;
    };

    const handleMapboxLayers = (layer: any, stateLayers: MapboxLayerType[]) => {
        const existingLayer = stateLayers.find(
            (stateLayer: MapboxLayerType) => layer.id === stateLayer.id || stateLayer.id.includes(layer.id)
        );

        if (existingLayer) {
            if (existingLayer.visible === layer.visible) {
                return stateLayers;
            }
            stateLayers = stateLayers.filter((stateLayer: any) => stateLayer.id !== layer.id);
        }

        const newLayer: MapboxLayerType = {
            id: layer.id,
            mapboxLayerId: layer.data,
            visible: layer.visible,
            transitions: layer.transitions,
        };
        stateLayers = [...stateLayers, newLayer];

        return stateLayers;
    };

    const onChapterChange = (chapterBefore: Chapter | undefined, chapterAfter: Chapter) => {
        let deckglLayersCopy = [...deckglLayers];
        let mapboxLayersCopy = [...mapboxLayers];
        let popups: any = [];

        // Adding eventual onChapterExit layers from the chapter we are changing from
        chapterBefore &&
            chapterBefore.onChapterExit &&
            chapterBefore.onChapterExit.forEach((layerConfig: LayerConfig) => {
                let layer = { ...layerConfig, ...data.layers[layerConfig.id] };
                if (layer.type === 'geojson') {
                    deckglLayersCopy = handleDeckglLayers(layer, deckglLayersCopy);
                } else if (layer.type === 'mapbox') {
                    mapboxLayersCopy = handleMapboxLayers(layer, mapboxLayersCopy);
                }
            });

        // Adding eventual onChapterEnter layers from the chapter we are changing to
        chapterAfter &&
            chapterAfter.onChapterEnter &&
            chapterAfter.onChapterEnter.forEach((layerConfig: LayerConfig) => {
                let layer = { ...layerConfig, ...data.layers[layerConfig.id] };
                if (layer.popup) {
                    popups = [
                        ...popups,
                        {
                            feature: layer.data.features[0],
                            anchor: layer.popupAnchor,
                        },
                    ];
                }
                if (layer.type === 'geojson') {
                    deckglLayersCopy = handleDeckglLayers(layer, deckglLayersCopy);
                } else if (layer.type === 'mapbox') {
                    mapboxLayersCopy = handleMapboxLayers(layer, mapboxLayersCopy);
                }
            });
        setDeckglLayers(deckglLayersCopy);
        setMapboxLayers(mapboxLayersCopy);
        setPopups(popups);
    };

    const handleChangeStep = async (step: number, chapterAfter: Chapter, chapterBefore: Chapter | undefined) => {
        setActiveStep(step);
        if (step === 0) {
            setDeckglLayers([]);
            setMapboxLayers([]);
            setPopups([]);
        } else {
            onChapterChange(chapterBefore, chapterAfter);
        }

        setViewport({
            longitude: chapterAfter.location.center[0],
            latitude: chapterAfter.location.center[1],
            zoom: chapterAfter.location.zoom,
            bearing: chapterAfter.location.bearing,
            pitch: chapterAfter.location.pitch,
            transitionDuration: chapterAfter.location.duration,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: easeCubicInOut,
        });
    };

    return (
        <>
            {isJourneyMode && (
                <Scroll
                    chapters={data.chapters}
                    activeStep={activeStep ?? 0}
                    onChangeStep={handleChangeStep}
                    currentScrollTop={currentScrollTop}
                    setCurrentScrollTop={setCurrentScrollTop}
                    topOffset={300}
                >
                    {data.chapters.map((item, i) => {
                        return (
                            <div key={`step-${i}`}>
                                {item.content ? (
                                    <ChapterContent
                                        active={activeStep === i}
                                        justifyContent={item.content.alignmentX}
                                        alignItems={item.content.alignmentY}
                                    >
                                        {activeStep !== null && i === 0 && (
                                            <IntroductionChapter
                                                title={item.content.title}
                                                description={item.content.description}
                                            />
                                        )}
                                        {activeStep !== null && i > 0 && (
                                            <BasicStoryTemplate
                                                title={item.content.title}
                                                description={item.content.description}
                                                info={item.content.info}
                                                image={item.content.image}
                                            />
                                        )}
                                    </ChapterContent>
                                ) : (
                                    <EmptyContent />
                                )}
                            </div>
                        );
                    })}
                </Scroll>
            )}
        </>
    );
};

export default Story;
