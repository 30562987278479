import { Feature } from 'geojson';
import { FC } from 'react';
import styled from 'styled-components';
import { DetectionImage, PlanetThumbnail, TargetOfInterestImage } from '../../../shared/components/Popup';
import { ColorBall } from '../interactiveReport/PathfinderReport';
import { BorderFreeRounded, Title } from '../styles/Wrappers';
import { TransformWrapper } from './PathfinderList';
import { Arrow } from '../interactiveReport/reportInfo/ReportInfo';
import { ButtonLink } from '../../../shared/styles/Buttons';

const FocusedImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    aligh-items: center;
`;

const FocusedImage = styled.div`
    width: 360px;
    height: 360px;
    display: inline-block;
    overflow: hidden;
    border-radius: 5px;
`;

const InformationContainer = styled.div`
    margin: 5px 5px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Information = styled.div`
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
`;

const Banner = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const InformationType = styled(Information)`
    background-color: #525042;
`;
const SubTitle = styled.div`
    font-weight: 700;
`;

const SpaceOccuiperContainer = styled.div`
    display: flex;
`;

const ButtonLinkSenHub = styled(ButtonLink)`
    width: 60%;
    align-self: center;
`;

export const PathfinderChosenImage: FC<{
    boatMetaData: Feature | null;
    chosenImage: number | null;
    chosenDate: string | null;
    chosenTime: string | null;
    setChosenImage: (value: number | null) => void;
    setBoatMetaData: (value: Feature | null) => void;
    shipName: string | null;
}> = ({ boatMetaData, chosenImage, chosenDate, chosenTime, setChosenImage, setBoatMetaData, shipName }) => {
    const getSentinelHubUrl = () => {
        const date = boatMetaData!.properties!.timestamp.slice(0, 10);
        const startOfDate = `${date}T00:00:00.000Z`;
        const endOfDate = `${date}T23:59:59.000Z`;
        const datasetId = boatMetaData?.properties?.image_source === 'sentinel1' ? 'S1_AWS_IW_VVVH' : 'S2L1C';
        return `https://apps.sentinel-hub.com/eo-browser/?zoom=15&lat=${boatMetaData!.properties!.lat}&lng=${
            boatMetaData!.properties!.lon
        }&themeId=DEFAULT-THEME&visualizationUrl=https://services.sentinel-hub.com/ogc/wms/42924c6c-257a-4d04-9b8e-36387513a99c&datasetId=${datasetId}&fromTime=${startOfDate}&toTime=${endOfDate}`;
    };

    return (
        <BorderFreeRounded>
            <Banner>
                <Arrow
                    onClick={(e) => {
                        setBoatMetaData(null);
                        setChosenImage(null);
                    }}
                >
                    <img src="/images/icons/ArrowLeft.svg"></img>
                </Arrow>
                <Title>{shipName}</Title>
            </Banner>
            {boatMetaData && boatMetaData.properties ? (
                <>
                    <FocusedImageWrapper>
                        <FocusedImage>
                            {boatMetaData?.properties?.image_source !== 'planet_scope' ? (
                                <TransformWrapper $farNorthScaling={boatMetaData?.properties?.lat}>
                                    {typeof chosenImage === 'number' ? (
                                        <DetectionImage image_path={chosenImage} />
                                    ) : (
                                        <TargetOfInterestImage feature={{ ...boatMetaData?.properties }} />
                                    )}
                                </TransformWrapper>
                            ) : (
                                <PlanetThumbnail image_path={boatMetaData.properties.preview_url} />
                            )}
                        </FocusedImage>
                    </FocusedImageWrapper>
                    <InformationContainer>
                        <SpaceOccuiperContainer>
                            <InformationType>
                                <ColorBall />
                                Known ship
                            </InformationType>
                            <div />
                        </SpaceOccuiperContainer>
                        {typeof boatMetaData?.properties?.image_source === 'string' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img src="/images/icons/satellite.svg" alt="no source" />
                                    <b>Satellite: </b>
                                    <span>{boatMetaData.properties?.image_source}</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof boatMetaData?.properties?.lon === 'number' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img src="/images/icons/longitude.svg" alt="no Longitude" />
                                    <SubTitle>Longitude: </SubTitle>
                                    <span>{Math.round(boatMetaData.properties?.lon * 100000000) / 100000000}</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof boatMetaData?.properties?.lat === 'number' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img
                                        onDoubleClick={() => window.open(getSentinelHubUrl(), '_blank')}
                                        src="/images/icons/latitude.svg"
                                        alt="no Latitude"
                                    />
                                    <SubTitle>Latitude: </SubTitle>
                                    <span>{Math.round(boatMetaData.properties?.lat * 100000000) / 100000000}</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof boatMetaData.properties?.speed === 'number' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img src="/images/icons/speedometer.svg" alt="no speed" />
                                    <SubTitle>AIS Speed:</SubTitle>
                                    <span>{boatMetaData.properties?.speed} kn</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof chosenDate === 'string' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img src="/images/icons/calendar.svg" alt="no timestamp" />
                                    <SubTitle>Time: </SubTitle>
                                    <span>
                                        {chosenDate.slice(4)}, {chosenTime?.slice(0, 5) + ' GMT'}
                                    </span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        <ButtonLinkSenHub href={getSentinelHubUrl()} target={'_blank'}>
                            Open in SentinelHub
                        </ButtonLinkSenHub>
                    </InformationContainer>
                </>
            ) : (
                <InformationContainer>
                    <div style={{ height: '50px' }}>missing image information</div>
                </InformationContainer>
            )}
        </BorderFreeRounded>
    );
};
