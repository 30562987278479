import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../auth/AuthProvider';
import { Spinner } from '../shared/styles/Spinner';
import { useIsAdmin } from '../requests/reports';
import { mmsiFlags } from '../shared/constants';
import { useVesselNameSearch } from '../requests/search';

const AbsoluteSpinner = styled(Spinner)`
    position: absolute;
`;
const Container = styled.div`
    width: 100vw;
    height: 100vh;
    text-align: center;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledInput = styled.input`
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
`;

const StyledResults = styled.div`
    margin-top: 10px;
`;

const StyledResultItem = styled.div`
    padding: 8px;
    margin: 4px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const StyledFeedbackMessage = styled.div`
    margin-top: 10px;
    color: #777;
`;

const SearchComponent: React.FC = () => {
    const { token } = useAuth();
    const { data: isAdmin } = useIsAdmin(token);
    const [searchInput, setSearchInput] = useState('');
    const { data: searchResults = [], isLoading, isError, refetch } = useVesselNameSearch(searchInput);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const cleanedInput = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
        setSearchInput(cleanedInput);
    };
    useEffect(() => {
        let timeoutId: NodeJS.Timeout | undefined;
        const delay = 300;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            refetch();
        }, delay);
        return () => clearTimeout(timeoutId);
    }, [searchInput, refetch]);
    if (isError || !isAdmin) {
        return (
            <Container>
                <div>Fetching data failed</div>
            </Container>
        );
    }
    return (
        <Container>
            <StyledContainer>
                <StyledInput
                    type="text"
                    value={searchInput}
                    onChange={handleInputChange}
                    placeholder="Enter your search term"
                />
                <StyledResults>
                    <div>Flag - Name - MMSI - IMO</div>
                    {isLoading && <AbsoluteSpinner />}
                    {searchResults.map((result, i) => {
                        const flag = mmsiFlags[result.mmsi.toString().substring(0, 3)] || '💡';
                        return (
                            <StyledResultItem key={i}>
                                {flag} - {result.name} - {result.mmsi} - {result.imo}
                            </StyledResultItem>
                        );
                    })}
                    {searchResults.length === 0 && !isLoading && (
                        <StyledFeedbackMessage>No results found for the given search term.</StyledFeedbackMessage>
                    )}
                </StyledResults>
            </StyledContainer>
        </Container>
    );
};

export default SearchComponent;
