import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useClearviewMmsiExample } from '../requests/insights';
import { DetectionImage } from '../shared/components/Popup';
import { apiBaseUrl } from '../utils/env';
import { ClearViewSearchBar } from './ClearViewReport';
import { Border, PaddingWrapper } from './Report/styles/Wrappers';
import { SimpleDateSelector } from './Report/interactiveReport/reportInfo/DateSelector';
import { Button } from '../shared/styles/Buttons';
import { DrawButton } from '../shared/components/DrawToggler';

const BoatImageGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

const CalenderHolder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const ImageWrapper = styled.div<{ $isClicked?: boolean | null }>`
    display: flex;
    width: 200px;
    overflow: hidden;
    box-sizing: border-box;
    border: ${(props) => (props.$isClicked ? '4px solid #208405' : '0px solid transparent')};
    ${(props) => (props.$isClicked ? '4px solid transparent' : '&:hover {border: 2px solid #ecda80;}')};
`;

const ImageOpacity = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    text-justify: center;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.3);
`;

const TextOnScreen = styled.div`
    font-size: 40;
    justify-self: center;
    font-weight: 800;
    width: 80%;
    height: 30px;
    z-index: 2;
    color: white;
    opacity: 0.9;
    background-color: #104205;
    border-radius: 10px;
`;

const CVErrorMessageContainer = styled.div`
    background-color: #c95b5b;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
`;

export const ClearViewMmsiHolder: FC<{
    setFindThisBoat: (value: string[] | null) => void;
    fromDate: Date | null;
    toDate: Date | null;
    drawMode: boolean;
    setFromDate: (value: Date | null) => void;
    setToDate: (value: Date | null) => void;
    setDrawMode: (value: boolean) => void;
    isPolygon: boolean;
    mmsiFromURL: number | null;
    setClearviewErrorMsg: (value: string | null) => void;
}> = ({
    setFindThisBoat,
    fromDate,
    toDate,
    drawMode,
    setFromDate,
    setToDate,
    setDrawMode,
    isPolygon,
    mmsiFromURL,
    setClearviewErrorMsg,
}) => {
    const [mmsiOfInterestToQuery, setMmsiOfInterestToQuery] = useState<number | null>(null);
    const [mmsiOfInterest, setMmsiOfInterest] = useState<number | null>(mmsiFromURL);
    const { data: mmsiExampleData } = useClearviewMmsiExample(mmsiOfInterestToQuery, setClearviewErrorMsg);
    const [highlightedImages, setHighlightedImages] = useState<number[] | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [validImageData, setValidImageData] = useState<boolean>(false);

    useEffect(() => {
        if (mmsiFromURL) {
            setMmsiOfInterest(mmsiFromURL);
            setMmsiOfInterestToQuery(mmsiFromURL);
        }
    }, [mmsiFromURL]);

    useEffect(() => {
        if (mmsiExampleData && mmsiExampleData.features) {
            if (mmsiExampleData.features.length > 0) {
                setValidImageData(true);
                setErrorMessage(null);
            } else {
                setValidImageData(false);
                setErrorMessage('We have no images of vessel ' + mmsiOfInterestToQuery + ', try another mmsi');
            }
        }
    }, [mmsiExampleData]);

    const handleMmsiChange = (event: any) => {
        const userInput = event.target.value;
        const isInt = Number(userInput);
        if (isInt || userInput === '') {
            setMmsiOfInterest(userInput);
        }
    };
    const handleMmsiSearchClick = () => {
        if (mmsiOfInterest) {
            setMmsiOfInterestToQuery(mmsiOfInterest);
        }
    };

    const HandleSearchVesselClick = (indeces: number[]) => {
        if (mmsiExampleData && mmsiExampleData.features) {
            const uuids: string[] = [];
            indeces.map((idx) => {
                const feature = mmsiExampleData.features[idx];
                if (feature && feature.properties && feature.properties.uuid) {
                    uuids.push(feature.properties.uuid);
                }
            });
            setFindThisBoat(uuids);
        }
    };
    const HandleAddHighlightVessel = (index: number) => {
        if (highlightedImages === null) {
            setHighlightedImages([index]);
        } else {
            // append if exist
            setHighlightedImages([...highlightedImages, index]);
        }
    };
    const HandleRemoveHighlightVessel = (index: number) => {
        if (highlightedImages?.includes(index)) {
            // Remove if already clicked
            const indexRemoved = highlightedImages.filter((element) => {
                return element !== index;
            });
            setHighlightedImages(indexRemoved);
        }
    };

    useEffect(() => {
        if (fromDate !== null && toDate !== null) {
            if (fromDate > toDate) {
                setFromDate(null);
            }
        }
    }, [fromDate]);
    useEffect(() => {
        if (fromDate !== null && toDate !== null) {
            if (fromDate > toDate) {
                setToDate(null);
            }
        }
    }, [toDate]);
    return (
        <>
            <PaddingWrapper>
                <ClearViewSearchBar
                    type="text"
                    onChange={handleMmsiChange}
                    placeholder={mmsiFromURL ? String(mmsiFromURL) : 'Insert MMSI Number'}
                />
                <CalenderHolder>
                    <SimpleDateSelector
                        buttonText={fromDate !== null ? fromDate.toDateString().slice(4) : 'Start Date'}
                        date={fromDate}
                        setDate={setFromDate}
                    />
                    <SimpleDateSelector
                        buttonText={toDate !== null ? toDate.toDateString().slice(4) : 'End Date'}
                        date={toDate}
                        setDate={setToDate}
                    />
                </CalenderHolder>
                <DrawButton
                    isFinished={isPolygon}
                    onClick={() => setDrawMode(!drawMode)}
                    isDrawMode={drawMode}
                ></DrawButton>
                <Button background="#886" onClick={handleMmsiSearchClick}>
                    Get Known Images
                </Button>
                {validImageData ? (
                    <>
                        <Border />
                        <Button
                            background="#348445"
                            onClick={() => {
                                if (highlightedImages !== null) {
                                    HandleSearchVesselClick(highlightedImages);
                                }
                            }}
                        >
                            Find Matching Images
                        </Button>
                    </>
                ) : null}
            </PaddingWrapper>
            {validImageData && mmsiExampleData ? (
                <BoatImageGrid>
                    {mmsiExampleData.features.map((item, index) => {
                        if (!!item.properties && item.properties.uuid) {
                            return (
                                <div key={index} style={{ position: 'relative' }}>
                                    {highlightedImages?.includes(index) ? (
                                        <ImageOpacity onClick={() => HandleRemoveHighlightVessel(index)}>
                                            <TextOnScreen>Chosen Vessel</TextOnScreen>
                                        </ImageOpacity>
                                    ) : null}
                                    <ImageWrapper
                                        onClick={() => {
                                            HandleAddHighlightVessel(index);
                                        }}
                                        $isClicked={highlightedImages?.includes(index)}
                                    >
                                        <DetectionImage
                                            key={index}
                                            image_path={
                                                apiBaseUrl() +
                                                '/insights/image/sentinel2/' +
                                                item.properties.uuid +
                                                '.png'
                                            }
                                        />
                                    </ImageWrapper>
                                </div>
                            );
                        }
                    })}
                </BoatImageGrid>
            ) : errorMessage ? (
                <PaddingWrapper>
                    <CVErrorMessageContainer>{errorMessage} </CVErrorMessageContainer>
                </PaddingWrapper>
            ) : null}
        </>
    );
};
