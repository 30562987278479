import { FC, useState } from 'react';
import styled from 'styled-components';
import constants from '../../../../shared/constants';
import { IReportInfo } from './ReportInfo';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
`;

const FilterButton = styled.div<{ selected?: Boolean }>`
    background-color: ${(props) => (props.selected ? 'white' : 'rgba(255, 255, 255, 0.15)')};
    border: 2px solid ${(props) => (props.selected ? 'white' : 'rgba(255, 255, 255, 0.2)')};
    color: ${(props) => (props.selected ? constants.colors.pallet.black : 'white')};
    padding: 10px 15px;
    text-align: center;
    font-size: 17px;
    // margin: 4px 17px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
    }
    &:active {
        outline: none;
        border: none;
    }
    &:focus {
        outline: 0;
    }
`;

const LargerDatePicker = styled(DatePicker)`
    // height: 30px;
    // font-size: 15px;
    text-align: center;
    // border-radius: 5px;
`;

const SimpleDateSelectorFilterButton = styled(FilterButton)<{ selected?: Boolean }>`
    margin: 10px 0;
`;

const DateWrapper = styled.div`
    position: absolute;
    z-index: 1001;
`;
interface IDateSelector extends IReportInfo {
    openDatePicker: Boolean;
    setOpenDatePicker: Function;
}

export const DateSelector: FC<IDateSelector> = (props) => {
    const { index, setIndex, availableDates, openDatePicker, setOpenDatePicker } = props;

    const [startDate, setStartDate] = useState<Date | null>(availableDates[index]);

    const updateDate = (date: Date | null) => {
        if (date !== null) {
            setStartDate(date);
            let newIndex = availableDates.findIndex((ad) => {
                return ad.toDateString() === date.toDateString();
            });
            newIndex !== -1 && setIndex(newIndex);
            setOpenDatePicker(false);
        }
    };

    return (
        <>
            <label>Navigate Web Report by Date</label>
            <ButtonWrapper>
                <FilterButton
                    onClick={() => {
                        setStartDate(null);
                        setIndex(0);
                    }}
                    selected={Boolean(index === 0)}
                >
                    First
                </FilterButton>
                <div>
                    <FilterButton
                        selected={openDatePicker}
                        onClick={() => {
                            setOpenDatePicker(!openDatePicker);
                        }}
                    >
                        Calendar
                    </FilterButton>
                    {openDatePicker && (
                        <DateWrapper>
                            <DatePicker
                                selected={startDate}
                                onChange={updateDate}
                                openToDate={availableDates[index]}
                                includeDates={availableDates}
                                inline
                            />
                        </DateWrapper>
                    )}
                </div>
                <FilterButton
                    onClick={() => {
                        setStartDate(null);
                        setIndex(availableDates.length - 1);
                    }}
                    selected={Boolean(index === availableDates.length - 1)}
                >
                    Latest
                </FilterButton>
            </ButtonWrapper>
        </>
    );
};

export const SimpleDateSelector: FC<{
    date: Date | null;
    setDate: (value: Date | null) => void;
    buttonText?: string | null;
    showMonthDropdown?: boolean | null;
    showYearDropdown?: boolean | undefined;
}> = ({ date, setDate, buttonText, showYearDropdown }) => {
    const [openDatePicker, toggleOpenDatePicker] = useState<boolean>(false);

    const handleNewDate = (e: Date | null) => {
        if (e !== null) {
            setDate(e);
            toggleOpenDatePicker(!openDatePicker);
        }
    };
    return (
        <div>
            <SimpleDateSelectorFilterButton
                selected={openDatePicker}
                onClick={() => {
                    toggleOpenDatePicker(!openDatePicker);
                }}
            >
                {buttonText || 'Open Calendar'}
            </SimpleDateSelectorFilterButton>
            {openDatePicker && (
                <DateWrapper>
                    <DatePicker
                        selected={date}
                        onChange={handleNewDate}
                        openToDate={date === null ? new Date() : date}
                        inline
                        showYearDropdown={showYearDropdown}
                    />
                </DateWrapper>
            )}
        </div>
    );
};

export const DateSelectorWithYears: FC<{
    date: Date | null;
    setDate: (date: Date | null) => void;
    maxDate?: Date | null;
    minDate?: Date | null;
}> = ({ date, setDate, maxDate, minDate }) => {
    const earliestYear = 2015;
    const earliestDate = minDate ? minDate : new Date(earliestYear, 0, 1);
    return (
        <LargerDatePicker
            showIcon
            selected={date}
            onChange={(date: Date | null) => setDate(date)}
            dateFormat="dd-MM-yyyy"
            maxDate={maxDate}
            minDate={earliestDate}
            showMonthDropdown
            showYearDropdown
        />
    );
};
