import { useQuery } from 'react-query';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { getRequestHeaders, vake_api_server_url } from './helpers';

export const useDetectionImage = (image_path: string) => {
    const [token] = useLocalStorage<string | null | undefined>('token', null);
    return useQuery(
        ['image', image_path],
        async () => {
            const res = await fetch(image_path, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }
            const blob = await res.blob();
            return URL.createObjectURL(blob);
        },
        {
            staleTime: Infinity,
        }
    );
};

export const usePlanetThumbnail = (image_path: string) => {
    const [token] = useLocalStorage<string | null>('token', null);
    const url = `${vake_api_server_url}/insights/image/planetimage?planet_id=${image_path}`;
    return useQuery(
        ['id', image_path],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });
            if (!res.ok) {
                throw new Error(res.statusText);
            }

            const blob = await res.blob();
            return URL.createObjectURL(blob);
        },
        {
            staleTime: Infinity,
        }
    );
};

export const useEarthEngineImage = (lon: Number, lat: Number, date: Date, source: string) => {
    const [token] = useLocalStorage<string | null>('token', null);
    const params = `lon=${lon}&lat=${lat}&date=${date.toISOString().split('T')[0]}`;
    const url = `${vake_api_server_url}/insights/image/${source}/?lon=${lon}&lat=${lat}&date=${
        date.toISOString().split('T')[0]
    }`;

    return useQuery<any>(
        ['earth_engine_image', source, params],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }
            const blob = await res.blob();
            return URL.createObjectURL(blob);
        },
        {
            staleTime: Infinity,
            cacheTime: 1000 * 60 * 60 * 24 * 365,
            retry: 1, // Could test custom retryDelay to test more strict exponential backoff
        }
    );
};
