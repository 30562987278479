import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { getRequestHeaders, vake_api_server_url } from '../requests/helpers';
import { useAuth } from '../auth/AuthProvider';
import { Spinner } from '../shared/styles/Spinner';
import constants, { mmsiFlags } from '../shared/constants';
import { CenteredElement } from './Report/styles/Wrappers';

const Container = styled.div`
    width: 75vw;
    height: auto;
    text-align: left;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    margin-top: 100px;
`;

const CleanedDataContainer = styled.div`
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 75%;
`;

const PFObjectHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
    background-color: none; #${constants.colors.pallet.lightBlack};  
`;

const MMSIHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 7vw;
    justify-content: space-between;
`;

const ReportBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const InformationHolder = styled.a`
    display: flex;
    flex-direction: row;
    width: 30vw;
    justify-content: space-around;
    text-decoration: none;
    &:hover {
        background-color: ${constants.colors.pallet.chilledBlue};
    }
`;
const DetailHolder = styled.div<{ $isLast?: boolean | null }>`
    padding: 10px 0px;
    border-bottom: ${(props) => (props.$isLast ? '2px solid black' : '0px solid transparent')};
`;
interface PathfinderReport {
    id: string;
    url?: string;
    bucket_path?: string;
    mmsi?: string;
    from?: string;
    to?: string;
}

const useGetUserPathfinderSubscriptions = () => {
    const { token } = useAuth();
    let url = `${vake_api_server_url}/dashboard/get-pathfinder-subscriptions`;
    return useQuery<PathfinderReport[]>(['pathfinder_reports'], async () => {
        const res = await fetch(url, {
            credentials: 'include',
            headers: getRequestHeaders(token),
        });

        if (!res.ok) {
            throw new Error(res.statusText);
        }

        return await res.json();
    });
};

interface DateIntervals {
    from: string;
    to: string;
}

interface PathfinderDetails {
    url: string;
    emoji: string;
    dateInterval: DateIntervals;
}
interface PathfindersDict {
    [mmsi: string]: PathfinderDetails[];
}

const returnValidURL = (Url: string | undefined, bucketPath: string | undefined) => {
    if (Url) {
        return Url;
    } else if (bucketPath) {
        return bucketPath;
    }
    return 'no url';
};
const returnListOfPFValues = (id: string | undefined, bucketPath: string | undefined) => {
    if (id) {
        return id.split('_');
    } else if (bucketPath) {
        return bucketPath.split('_');
    }
    return [];
};

// TODO: In the future this class and OverwatchReports can be shared
const PathfinderReports: React.FC<any> = () => {
    const { data: pathfinderReportItems, isLoading, isError } = useGetUserPathfinderSubscriptions();
    const [cleanPathfinderData, setCleanPathfinderData] = useState<PathfindersDict | null>(null);

    useEffect(() => {
        if (pathfinderReportItems) {
            var tempCleanedPathfinderData: PathfindersDict = {};
            pathfinderReportItems.forEach((item) => {
                const current_url: string = returnValidURL(item.id, item.bucket_path);
                const vesselInfo: string[] = returnListOfPFValues(item.id, item.bucket_path);
                if (current_url !== 'no url' && vesselInfo.length > 0) {
                    const mmsi = vesselInfo[0];
                    if (mmsi in tempCleanedPathfinderData) {
                        tempCleanedPathfinderData[mmsi].push({
                            url: window.location.origin + '/pathfinder/' + current_url,
                            dateInterval: { from: vesselInfo[1], to: vesselInfo[2] },

                            emoji: mmsiFlags[mmsi.substring(0, 3)],
                        });
                    } else {
                        tempCleanedPathfinderData[mmsi] = [
                            {
                                url: window.location.origin + '/pathfinder/' + current_url,
                                dateInterval: { from: vesselInfo[1], to: vesselInfo[2] },
                                emoji: mmsiFlags[mmsi.substring(0, 3)],
                            },
                        ];
                    }
                }
            });
            setCleanPathfinderData(tempCleanedPathfinderData);
        }
    }, [pathfinderReportItems]);

    if (isError) {
        return <CenteredElement>Fetching data failed</CenteredElement>;
    }
    if (isLoading) {
        return (
            <CenteredElement>
                <Spinner />
            </CenteredElement>
        );
    }
    return (
        <Container>
            {cleanPathfinderData ? (
                <CleanedDataContainer>
                    {Object.entries(cleanPathfinderData).map(([k, v]) => {
                        return (
                            <PFObjectHolder>
                                <MMSIHolder>
                                    <div style={{ fontSize: '40px' }}>{v[0].emoji}</div>
                                    {k}
                                </MMSIHolder>
                                {
                                    <ReportBlock>
                                        {v.map((details, index) => {
                                            return (
                                                <InformationHolder target={'_blank'} href={details.url}>
                                                    <DetailHolder $isLast={index !== v.length - 1}>
                                                        {details.dateInterval.from
                                                            ? 'From ' + details.dateInterval.from.split('T')[0]
                                                            : ' - '}
                                                    </DetailHolder>
                                                    <DetailHolder $isLast={index !== v.length - 1}>
                                                        {details.dateInterval.to
                                                            ? 'To ' + details.dateInterval.to.split('T')[0]
                                                            : ' - '}
                                                    </DetailHolder>
                                                    <DetailHolder $isLast={index !== v.length - 1}></DetailHolder>
                                                </InformationHolder>
                                            );
                                        })}
                                    </ReportBlock>
                                }
                            </PFObjectHolder>
                        );
                    })}
                </CleanedDataContainer>
            ) : (
                <CenteredElement>We found no data for this user</CenteredElement>
            )}
        </Container>
    );
};

export default PathfinderReports;
