import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useInsightByUuid } from '../requests/insights';
import { Feature } from 'geojson';
import { DetectionImage } from '../shared/components/Popup';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-item: space-between;
    margin: 100px 100px;
`;

const DetectionImagePlaceholder = styled.span`
    position: relative;
    display: flex;
    height: 300px;
    width: 350px;
    margin-bottom: 5%;
    align-items: center;

    border-radius: 11px;
`;

const Placeholderholder = styled.div`
    display: flex;
    flex-direction: column;
    height: 900px;
    margin-left: 100px;
    align-items: center;
    justify-content: space-between;
    overflow-y: scroll;
`;

const Placeholder = styled.span<{ $activated?: boolean | null }>`
    position: relative;
    display: flex;
    height: 300px;
    width: 550px;
    margin-bottom: 5%;
    align-items: center;
    background-color: ${(props) => (props.$activated ? '#339955' : '#555')};
    border-radius: 11px;
`;

const DarkPlaceholder = styled.span`
    display: flex;
    width: 500px;
    height: 280px;
    margin-top: 300px;
    align-items: center;
    justify-content: flex-end;
    background-color: #339955;
    border-radius: 11px;
`;

const InputPlaceholder = styled.span`
    display: flex;
    width: 500px;
    height: 280px;
    margin-top: 300px;
    align-items: center;
    margin-left: 50px;
    justify-content: flex-end;
    background-color: #555;
    border-radius: 11px;
`;

const IndexHolder = styled.div`
    position: absolute;
    font-size: 20px;
    top: 10px;
    left: 10px;
    font-weight: 600;
`;

const TextHolder = styled.div`
    height: 100%;
    align-items: space-around;
    flex-wrap: wrap;
    color: lightgrey;
    margin: 0 2vw;
    font-weight: 500;
`;

const TimeStampText = styled.div`
    margin-top: 30px;
    font-size: 1vw;
`;

const RemoveButton = styled.div`
    margin-top: 30px;
    font-size: 1vw;
    background-color: #bb4400;
    border-radius: 10px;
    align-self: center;
    padding: 10px;
`;

const Candidate: React.FC<{
    index: any;
    properties: any;
    // uuid: string;
    // timestamp: Date;
    // imagePath: string;
    activated: boolean | null;
    clickedCandidates: any;
    setClickedCandidates: any;
    // }> = ({ index, uuid, timestamp, imagePath, activated, clickedCandidates, setClickedCandidates }) => {
}> = ({ index, properties, activated, clickedCandidates, setClickedCandidates }) => {
    const {
        uuid,
        // image_source,
        timestamp,
        // correlated,
        // object_class,
        // matching_label,
        length,
        lon,
        lat,
        // object_class_score,
        // ais_length,
        // estimation_score,
        // matching_score,
        course,
        speed,
        mmsi,
        image_path,
        // distance_to_mmsi,
        // ais_length,
        // time_to_nearest_ais,
    } = properties;

    const formattedTimestamp = new Date(timestamp).toUTCString();

    const onClickCandidate = () => {
        if (!clickedCandidates.includes(index)) {
            setClickedCandidates([...clickedCandidates, index]);
        }
    };

    const handleRemoveClick = () => {
        setClickedCandidates(clickedCandidates.filter((number: any) => number !== index));
    };

    return (
        <Placeholder key={index} $activated={activated} onClick={() => onClickCandidate()}>
            <IndexHolder>{index}</IndexHolder>
            <DetectionImage image_path={image_path} />
            <TextHolder>
                {formattedTimestamp && <div style={{ fontWeight: '700' }}>Timestamp: {formattedTimestamp}</div>}
                {uuid && <div style={{ fontWeight: '700' }}>UUID: {uuid}</div>}
                {lon && <div style={{ fontWeight: '700' }}>Longitude: {lon}</div>}
                {lat && <div style={{ fontWeight: '700' }}>Latitude: {lat}</div>}
                {length && <div style={{ fontWeight: '700' }}>Length: {length}</div>}
                {course && <div style={{ fontWeight: '700' }}>Course: {course}</div>}
                {speed && <div style={{ fontWeight: '700' }}>Speed: {speed}</div>}
                {mmsi && <div style={{ fontWeight: '700' }}>MMSI: {mmsi}</div>}
                {/* <div>{activated ? uuid : 'Click for UUID'}</div> */}
                {activated && <RemoveButton onClick={() => handleRemoveClick()}>Remove this</RemoveButton>}
            </TextHolder>
        </Placeholder>
    );
};

function checkIfValidUUID(str: string) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
}

const VisualizeCandidates: FC = () => {
    useEffect(() => {
        document.title = 'Candidates';
    }, []);

    const [findThisBoat, setFindThisBoat] = useState<string | null>(null);
    const [couldBeTheseBoats, setCouldBeTheseBoats] = useState<string[] | null>(null);

    const { data: insights } = useInsightByUuid(couldBeTheseBoats);

    const [clickedCandidates, setClickedCandidates] = useState<number[]>([]);

    const handleFindThisChange = (event: any) => {
        const noSpace = event.target.value.replace(/\s+/g, '');
        const newElement = noSpace.replaceAll("'", '"').replaceAll('"', '');
        setFindThisBoat(newElement);
    };

    const handleCouldBeChange = (event: any) => {
        const noSpace = event.target.value.replace(/\s+/g, '');
        const elements = noSpace.split(',');
        const cand = elements.reduce((elements: any[], item: any) => {
            const newElement = item
                .replaceAll("'", '"') // replace all single quote with double quote
                .replaceAll('"', '') // replace all double quote with blank
                .replace('[', '')
                .replace(']', '')
                .replace(';', '');
            if (checkIfValidUUID(newElement)) {
                elements.push(newElement);
            }
            return elements;
        }, []);
        setCouldBeTheseBoats(cand);
    };

    return (
        <Wrapper>
            <DarkPlaceholder>
                <TextHolder>
                    <text style={{ fontWeight: '700', marginTop: '200px' }}> Ship to find </text>
                    <TimeStampText>
                        <text style={{ fontWeight: '700' }}>Input example</text>
                        <div>738c8c72-bd9a-4f3a-8628-e43b825844e1</div>
                    </TimeStampText>
                    <input type="text" onChange={handleFindThisChange} value={findThisBoat ? findThisBoat : ''} />
                </TextHolder>
                {findThisBoat ? (
                    <DetectionImage image_path={'https://api.vake.ai/insights/image/uuid/' + findThisBoat} />
                ) : (
                    <DetectionImagePlaceholder />
                )}
            </DarkPlaceholder>
            {insights ? (
                <Placeholderholder>
                    {insights.features.map((feature: Feature, index: any) => {
                        const insight = feature.properties;
                        const uuid = insight!.uuid;
                        if (uuid && uuid.length > 10) {
                            return (
                                <Candidate
                                    key={index}
                                    index={index}
                                    properties={insight}
                                    activated={clickedCandidates.includes(index)}
                                    clickedCandidates={clickedCandidates}
                                    setClickedCandidates={setClickedCandidates}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </Placeholderholder>
            ) : (
                <InputPlaceholder>
                    <TextHolder>
                        <text style={{ fontWeight: '700', marginTop: '200px' }}> List of potential candidates </text>
                        <input
                            type="text"
                            onChange={handleCouldBeChange}
                            value={couldBeTheseBoats ? couldBeTheseBoats : ''}
                        />
                    </TextHolder>
                    <DetectionImagePlaceholder />
                </InputPlaceholder>
            )}
        </Wrapper>
    );
};

export default VisualizeCandidates;
