// eslint-disable-next-line import/no-extraneous-dependencies
import { GeoJsonLayer } from '@deck.gl/layers/typed';
// import { DataFilterExtension } from '@deck.gl/extensions/typed';
import hexRgb from 'hex-rgb';

const hexToRgb = (hexCode: string): [number, number, number, number] => {
    let color = hexRgb(hexCode, { format: 'array' });
    let alpha = color.pop();
    color.push((alpha ?? 1) * 255);
    return color;
};

// const dataFilter = new DataFilterExtension({
//     filterSize: 1,
//     fp64: false,
//     countItems: false,
// });

const generateGeoJsonLayer = (
    id: string,
    data: any,
    visible = true,
    opacity = 1.0,
    isFill = false,
    lineColor: string = '#97dbf9',
    fillColor: string = '#86a2b396',
    pointRadius: number = 1000,
    lineWidth: number = 0.5
    // filterRange: number[] = [0, 30]
): GeoJsonLayer<any> => {
    return new GeoJsonLayer({
        id: id,
        data: data,
        pickable: false,
        visible: visible,
        opacity: opacity,
        stroked: true,
        filled: isFill,
        extruded: false,
        lineWidthMinPixels: 2,
        pointType: 'circle',
        getFillColor: hexToRgb(fillColor),
        getLineColor: hexToRgb(lineColor),
        getLineWidth: lineWidth,
        getPointRadius: pointRadius,

        transitions: {
            getFillColor: 3000,
            getLineColor: 3000,
            opacity: 3000,
            // getLineWidth: 3000,
            getPointRadius: 3000,
        },
        // getFilterValue: (d: any) => parseInt(d.id),
        // filterRange: filterRange,
        // extensions: [dataFilter],
    });
};

export default generateGeoJsonLayer;
