import { Feature, FeatureCollection } from 'geojson';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DetectionImage, PlanetThumbnail, TargetOfInterestImage } from '../../../shared/components/Popup';
import { IChosenBoatObject } from '../interactiveReport/PathfinderReport';
import { Border, BorderFreeRounded, Title } from '../styles/Wrappers';
import { PathfinderChosenImage } from './PathfinderChosenImage';
import { Arrow } from '../interactiveReport/reportInfo/ReportInfo';

const SideBar = styled.div`
    position: absolute;
    top: 50px;
    right: 5px;
    z-index: 1000;
    min-width: 175px;
    width: 20%;
    max-width: 325px;
    max-height: calc(100vh - 60px);
`;

const UnderTitle = styled.div`
    margin-top: 20px;
`;

const ImageHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 60vh;
    overflow-y: scroll;
    // padding-left: 5px;
`;

const ImageObjectWrapper = styled.span<{ $isSentinel?: boolean | null }>`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 2px solid transparent;
    background-color: ${(props) => (props.$isSentinel ? 'default' : '#1178aa')};
    &:hover {
        border: 2px solid #ecda80;
    }
    gap: 5px;
`;
const ImageWrapper = styled.div`
    width: 120px;
    height: 120px;
    display: inline-block;
    overflow: hidden;
    // width: 100%; /* Adjusts the width to fill the container */
    // height: auto; /* Maintains the aspect ratio */
    // object-fit: cover; /* Ensures the image covers the container without distortion */
    border-radius: 3px;
`;

const DateInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
`;

export const TransformWrapper = styled.div<{ $farNorthScaling?: number | null }>`
    transform: scale(${(props) => (props.$farNorthScaling && props.$farNorthScaling > 69 ? 1.27 : 1.09)});
`;
const ButtonsHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const ShowingIndeces = styled.div`
    margin-top: 20px;
    height: 20px;
    text-align: center;
`;

interface IDefiningBoat {
    image_path: number | null;
    datestring: string;
    time: string;
}

const GetBoatProperties = (item: IChosenBoatObject) => {
    const image_path = item?.image_path ? item.image_path : null;
    const date = item?.timestamp ? new Date(item?.timestamp).toUTCString().split(' ') : ' ';
    const datestring = date[0] + ' ' + date[2] + ' ' + date[1] + ' ' + date[3];
    const time = date[4] + ' GMT';
    const definingBoat: IDefiningBoat = { image_path: image_path, datestring: datestring, time: time };
    return definingBoat;
};

export const PathfinderList: FC<{
    aisPositions: FeatureCollection | undefined;
    chosenBoatObject: IChosenBoatObject | null;
    boatMetaData: Feature | null;
    setBoatMetaData: (value: Feature | null) => void;
    setHoveredPathElement: (value: number | null) => void;
    mmsi: string;
    shipName: string | null;
}> = ({ aisPositions, chosenBoatObject, boatMetaData, setBoatMetaData, setHoveredPathElement, mmsi, shipName }) => {
    const [positions, setPositions] = useState<Feature[]>([]);
    const [numCaptures, setNumCaptures] = useState<number | null>(null);
    const [chosenImage, setChosenImage] = useState<number | null>(null);
    const [chosenDate, setChosenDate] = useState<string | null>(null);
    const [chosenTime, setChosenTime] = useState<string | null>(null);
    const [startIndex, setStartIndex] = useState<number>(0);
    const [endIndex, setEndIndex] = useState<number>(-1);

    const buttonIncrement = 30;
    const listIncrement = 30;

    useEffect(() => {
        if (aisPositions) {
            setPositions(aisPositions.features);
            setNumCaptures(aisPositions.features.length);
            setEndIndex(aisPositions.features.length >= 30 ? 30 : aisPositions.features.length);
        }
    }, [aisPositions]);

    useEffect(() => {
        // If an AIS estimate popup/image is clicked in the map
        if (chosenBoatObject) {
            aisPositions?.features.some((item, index) => {
                // Looping through estimated AIS positions to find the index of the chosen ship (clicked popup/image)
                if (item.id == chosenBoatObject.id) {
                    const definingBoat: IDefiningBoat = GetBoatProperties({ ...item.properties });
                    setBoatMetaData(positions[index]);
                    if (positions.length < 40) {
                        setStartIndex(0);
                        setEndIndex(positions.length);
                    } else {
                        setStartIndex(index - 15 >= 0 ? index - 15 : 0);
                        setEndIndex(index + 15 <= positions?.length ? index + 15 : positions?.length);
                    }
                    setChosenImage(definingBoat.image_path);
                    setChosenDate(definingBoat.datestring);
                    setChosenTime(definingBoat.time);
                }
            });
        }
    }, [chosenBoatObject]);

    return (
        <SideBar>
            {!boatMetaData ? (
                <BorderFreeRounded>
                    <Border>
                        <Title>MMSI: {mmsi}</Title>
                        <UnderTitle>{numCaptures ? numCaptures + ' satellite images' : '  '}</UnderTitle>
                    </Border>
                    <ImageHolder>
                        {positions.slice(startIndex, endIndex).map((item, index) => {
                            const definingBoat: IDefiningBoat = GetBoatProperties({ ...item.properties });
                            const isSentinel = item.properties?.image_source !== 'planet_scope'; // TODO: Needs to be updated if we add more sources
                            return (
                                <ImageObjectWrapper
                                    key={index}
                                    $isSentinel={isSentinel}
                                    onClick={() => {
                                        const newIndex = startIndex + index;
                                        setBoatMetaData(positions[newIndex]);
                                        if (positions.length < 40) {
                                            setStartIndex(0);
                                            setEndIndex(positions.length);
                                        } else if (
                                            positions.length > listIncrement &&
                                            positions.length > newIndex + listIncrement
                                        ) {
                                            setEndIndex(positions.length);
                                            setStartIndex(positions.length - listIncrement);
                                        } else {
                                            setStartIndex(newIndex - 1 >= 0 ? newIndex - 1 : 0);
                                            setEndIndex(
                                                newIndex + listIncrement <= positions?.length
                                                    ? newIndex + listIncrement
                                                    : positions?.length
                                            );
                                        }
                                        setChosenImage(definingBoat.image_path);
                                        setChosenDate(definingBoat.datestring);
                                        setChosenTime(definingBoat.time);
                                    }}
                                    onMouseEnter={() => setHoveredPathElement(startIndex + index)}
                                    onMouseLeave={() => setHoveredPathElement(null)}
                                >
                                    <ImageWrapper>
                                        {isSentinel ? (
                                            <TransformWrapper $farNorthScaling={item.properties?.lat}>
                                                {definingBoat.image_path ? (
                                                    <DetectionImage image_path={definingBoat.image_path} />
                                                ) : (
                                                    <TargetOfInterestImage feature={{ ...item.properties }} />
                                                )}
                                            </TransformWrapper>
                                        ) : (
                                            <PlanetThumbnail image_path={item.properties?.preview_url} />
                                        )}
                                    </ImageWrapper>
                                    <DateInfo>
                                        <span>{definingBoat.datestring}</span>
                                        <span>{definingBoat.time}</span>
                                    </DateInfo>
                                </ImageObjectWrapper>
                            );
                        })}
                    </ImageHolder>
                    {numCaptures ? (
                        <BorderFreeRounded>
                            <ShowingIndeces>
                                Images {startIndex + 1} to {endIndex}
                            </ShowingIndeces>
                            <ButtonsHolder>
                                <Arrow
                                    disabled={startIndex === 0}
                                    onClick={() => {
                                        const newStartIndex =
                                            startIndex - buttonIncrement >= 0 ? startIndex - buttonIncrement : 0;
                                        const newEndIndex =
                                            newStartIndex <= 0
                                                ? buttonIncrement > positions.length
                                                    ? positions.length
                                                    : buttonIncrement
                                                : endIndex - buttonIncrement;

                                        setStartIndex(newStartIndex);
                                        setEndIndex(newEndIndex);
                                    }}
                                >
                                    <img src="/images/icons/ArrowLeft.svg" alt="Previous date" />
                                </Arrow>
                                <Arrow
                                    disabled={endIndex === positions.length}
                                    onClick={() => {
                                        const newEndIndex =
                                            endIndex + buttonIncrement <= numCaptures
                                                ? endIndex + buttonIncrement
                                                : numCaptures;
                                        const newStartIndex =
                                            newEndIndex >= numCaptures
                                                ? numCaptures - buttonIncrement < 0
                                                    ? 0
                                                    : numCaptures - buttonIncrement
                                                : startIndex + buttonIncrement;

                                        setStartIndex(newStartIndex);
                                        setEndIndex(newEndIndex);
                                    }}
                                >
                                    <img src="/images/icons/ArrowRight.svg"></img>
                                </Arrow>
                            </ButtonsHolder>
                        </BorderFreeRounded>
                    ) : null}
                </BorderFreeRounded>
            ) : (
                <PathfinderChosenImage
                    boatMetaData={boatMetaData}
                    chosenImage={chosenImage}
                    chosenDate={chosenDate}
                    chosenTime={chosenTime}
                    setBoatMetaData={setBoatMetaData}
                    setChosenImage={setChosenImage}
                    shipName={shipName}
                />
            )}
        </SideBar>
    );
};
