import mapboxgl, { LngLatLike } from 'mapbox-gl';
import { queryClient } from '../App';
import { ReportType } from './enums';
import { QueryClientProvider } from 'react-query';
import { TargetsOfInterestPopup, ReportPopup, TrackPopup, GapPopup } from '../shared/components/Popup';
import { Feature, FeatureCollection, GeoJsonProperties } from 'geojson';
import { layerExists } from '../shared/components/PathfinderMap';
import { createRoot } from 'react-dom/client';

export const addMouseMoveHoverEffect = (
    currMap: mapboxgl.Map,
    layer_id: string,
    isTrack?: Boolean,
    popupRef?: React.MutableRefObject<mapboxgl.Popup>
) => {
    let featureId: string | number | undefined;
    currMap.on('mouseenter', layer_id, (e) => {
        currMap.getCanvas().style.cursor = 'pointer';
        if (e.features && e.features.length > 0) {
            // "previous" hovered feature is set to hover: false
            if (featureId !== undefined) {
                currMap.setFeatureState(
                    {
                        source: layer_id,
                        id: featureId,
                    },
                    { hover: false }
                );
            }

            featureId = e.features[0].id;
            // "new" feature is set to hover: true
            currMap.setFeatureState(
                {
                    source: layer_id,
                    id: featureId,
                },
                {
                    hover: true,
                }
            );

            if (isTrack && popupRef) {
                const feature: any = e.features[0];
                // create popup node
                const popupNode = document.createElement('div');
                const popupRoot = createRoot(popupNode);

                popupRoot.render(
                    <QueryClientProvider client={queryClient}>
                        <TrackPopup feature={feature.properties} />
                    </QueryClientProvider>
                );

                const coordinate = feature.geometry.coordinates[0];

                const lnglat = {
                    lng: coordinate[0],
                    lat: coordinate[1],
                };

                // set popup on map
                popupRef.current = new mapboxgl.Popup({ offset: 20 })
                    .setLngLat(lnglat)
                    .setDOMContent(popupNode)
                    .addTo(currMap);
            }
        }
    });
    currMap.on('mouseleave', layer_id, () => {
        if (featureId !== undefined) {
            currMap.setFeatureState(
                {
                    source: layer_id,
                    id: featureId,
                },
                {
                    hover: false,
                }
            );
        }
        featureId = undefined;
        // Reset the cursor style
        currMap.getCanvas().style.cursor = '';
        if (isTrack && popupRef) {
            popupRef.current.remove();
        }
    });
};

export const addMouseClickAisGapEffect = (currMap: mapboxgl.Map, layer_id: string, gapLayers: string[]) => {
    let lineId: string | number | undefined;
    currMap.on('click', layer_id, (e) => {
        currMap.getCanvas().style.cursor = 'pointer';
        gapLayers = gapLayers.filter((layer) => layerExists(currMap, layer));

        if (e.features && e.features.length > 0) {
            if (lineId !== undefined) {
                currMap.setFeatureState(
                    {
                        source: layer_id,
                        id: lineId,
                    },
                    { itIsClicked: false }
                );
            }
            if (lineId === e.features[0].id) {
                gapLayers.forEach((layer) => {
                    currMap.setFilter(layer, ['==', 'gap_ix', '']);
                    if (layerExists(currMap, `${layer}_hover`)) {
                        currMap.setFilter(`${layer}_hover`, ['==', 'gap_ix', '']);
                    }
                });
                lineId = undefined;
                return;
            }

            let gap_ix = e.features[0].properties?.gap_ix;

            gapLayers.forEach((layer) => {
                currMap.setFilter(layer, ['==', 'gap_ix', gap_ix]);
                if (layerExists(currMap, `${layer}_hover`)) {
                    currMap.setFilter(`${layer}_hover`, ['==', 'gap_ix', gap_ix]);
                }
            });

            lineId = e.features[0].id;

            currMap.setFeatureState(
                {
                    source: layer_id,
                    id: lineId,
                },
                {
                    itIsClicked: true,
                }
            );
        }
    });
};

const createClearviewUrlFromFeature = (feat: Feature, gap_feat: Feature | undefined): string => {
    let gap_query_params: string = '';
    if (gap_feat && gap_feat.geometry && gap_feat.geometry.type === 'Polygon') {
        gap_query_params = '&areaCoordinates=' + JSON.stringify(gap_feat.geometry.coordinates);
    }
    if (feat.properties) {
        const mmsi = feat.properties.mmsi ? feat.properties.mmsi : null;
        const start_timestamp = feat.properties.start_timestamp ? feat.properties.start_timestamp : null;
        const end_timestamp = feat.properties.end_timestamp ? feat.properties.end_timestamp : null;
        if (mmsi && start_timestamp && end_timestamp) {
            return 'mmsi=' + mmsi + '&fromDate=' + start_timestamp + '&toDate=' + end_timestamp + gap_query_params;
        }
    }
    return '';
};

const findPopupPlacementOnLinestring = (feature: Feature, clickedLngLat: mapboxgl.LngLat): mapboxgl.LngLat => {
    if (feature.geometry.type === 'LineString') {
        const startPoint = feature.geometry.coordinates[0];
        const endPoint = feature.geometry.coordinates[1];
        return new mapboxgl.LngLat((startPoint[0] + endPoint[0]) / 2, (startPoint[1] + endPoint[1]) / 2);
    } else return clickedLngLat; // In case something goes wrong
};

export const addGapPopup = (
    currMap: mapboxgl.Map,
    layer_id: string,
    popupRef: React.MutableRefObject<mapboxgl.Popup>,
    possibility_area_in_gap_at_datatake_sensing_time?: FeatureCollection | undefined
) => {
    currMap.on('click', layer_id, (e) => {
        if (e.features && e.features.length) {
            const feature = e.features[0];
            const gap_ix: number | undefined = feature.properties?.gap_ix;
            let relevant_gap_ = undefined;
            const popupCoords = findPopupPlacementOnLinestring(feature, e.lngLat);
            if (possibility_area_in_gap_at_datatake_sensing_time && gap_ix) {
                // Find the related gap
                relevant_gap_ = possibility_area_in_gap_at_datatake_sensing_time.features?.find((feat) => {
                    return feat && feat.properties && feat.properties.gap_ix === gap_ix;
                });
            }
            const clearviewUrlQuery = createClearviewUrlFromFeature(feature, relevant_gap_);
            if (clearviewUrlQuery === '') {
                return;
            }
            feature.properties = parseGeoJsonPropertiesNullValues(feature.properties);

            // create popup node
            const popupNode = document.createElement('div');
            const popupRoot = createRoot(popupNode);

            popupRoot.render(
                <QueryClientProvider client={queryClient}>
                    <GapPopup clearviewLink={window.location.origin + '/clearview?' + clearviewUrlQuery} />
                </QueryClientProvider>
            );

            // remove other popup from map
            if (popupRef) {
                popupRef.current.remove();
            }
            // set popup on map
            popupRef.current = new mapboxgl.Popup({ offset: 15, closeOnMove: true })
                .setLngLat(popupCoords)
                .setDOMContent(popupNode)
                .addTo(currMap);
        }
    });
};

export const addPopupOnListElement = (
    map: mapboxgl.Map,
    popupRef: React.MutableRefObject<mapboxgl.Popup>,
    feature: Feature,
    reportType: ReportType,
    coord: LngLatLike
) => {
    const popupNode = document.createElement('div');
    const popupRoot = createRoot(popupNode);

    popupRoot.render(
        <QueryClientProvider client={queryClient}>
            <ReportPopup feature={feature.properties} reportType={reportType} />
        </QueryClientProvider>
    );
    // set popup on map

    if (popupRef && popupRef.current) {
        popupRef.current.remove();
    }
    // set popup on map
    popupRef.current = new mapboxgl.Popup({ offset: 5 }).setLngLat(coord).setDOMContent(popupNode).addTo(map);
};

export const addTargetsOfInterestPopup = (
    currMap: mapboxgl.Map,
    layer_id: string,
    popupRef: React.MutableRefObject<mapboxgl.Popup>,
    reportType: ReportType
) => {
    currMap.on('click', layer_id, (e) => {
        if (e.features && e.features.length) {
            const feature = e.features[0];

            feature.properties = parseGeoJsonPropertiesNullValues(feature.properties);

            // create popup node
            const popupNode = document.createElement('div');
            const popupRoot = createRoot(popupNode);

            popupRoot.render(
                <QueryClientProvider client={queryClient}>
                    <TargetsOfInterestPopup feature={feature.properties} reportType={reportType} />
                </QueryClientProvider>
            );
            // set popup on map

            if (popupRef && popupRef.current) {
                popupRef.current.remove();
            }
            // set popup on map
            popupRef.current = new mapboxgl.Popup({ offset: 15 })
                .setLngLat(e.lngLat)
                .setDOMContent(popupNode)
                .addTo(currMap);
        }
    });
};

export const parseGeoJsonPropertiesNullValues = (properties: GeoJsonProperties) => {
    properties = JSON.parse(
        JSON.stringify(properties, function (key, value) {
            return value === 'null' ? null : value;
        })
    );

    // Parse strings from maplayer to null-values correctly
    for (const key in properties) {
        properties[key] = properties[key] === 'null' ? null : properties[key];
    }

    return properties;
};
